var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "AddUserPromotionForm",
        attrs: {
          header: { isClosable: true },
          buttons: _vm.modalButtons,
          size: _vm.SIZES.small,
          "data-test-id": "add_promotion-form",
          title: "Add promotion",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "alerts" },
        [
          _c("StoreNotificationComponent", {
            attrs: {
              "store-domain": _vm.DOMAINS_MODEL.crm.users,
              scope: _vm.USER_SCOPES.addPromotionForm,
              element: "promotion",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "MuiValidationWrapper",
            { on: { areAllValid: (isValid) => (_vm.isFormValid = isValid) } },
            [
              _c("p", { staticClass: "emobg-body-1 mb-4" }, [
                _vm._v(
                  " Only active promotions with valid expiration date are available. "
                ),
              ]),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("MuiAlgoliaSelect", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                        },
                        expression:
                          "{\n            isRequired: true,\n          }",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: {
                      index: _vm.ALGOLIA_INDEXES.promotions,
                      title: (result) => result.name || result.internal_name,
                      filters: `${_vm.getOperatorFilter({
                        attribute: "cs_operator_fk",
                      })} AND deleted_at: null AND active: Active`,
                      "data-test-id": "promotion-select",
                      "no-cache": "",
                      "path-value": "uuid",
                      label: "Promotion*",
                      placeholder: "Select a promotion",
                      name: "promotionUuid",
                    },
                    model: {
                      value: _vm.inputs.promotionUuid,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "promotionUuid", $$v)
                      },
                      expression: "inputs.promotionUuid",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }