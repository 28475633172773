var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "EditUserPromotionForm",
        attrs: {
          header: { isClosable: true },
          buttons: _vm.modalButtons,
          "data-test-id": "edit_promotion-form",
          title: "Edit promotion",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "alerts" },
        [
          _c("StoreNotificationComponent", {
            attrs: {
              "store-domain": _vm.DOMAINS_MODEL.crm.users,
              scope: _vm.USER_SCOPES.editPromotionForm,
              "is-editing": "",
              element: "promotion",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "MuiValidationWrapper",
            { on: { areAllValid: (isValid) => (_vm.isFormValid = isValid) } },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("ContentCellComponent", {
                      staticClass: "mb-4",
                      attrs: {
                        label: "Internal name",
                        value: _vm.userPromotion.internalName,
                      },
                    }),
                    _c("ContentCellComponent", {
                      staticClass: "mb-4",
                      attrs: { label: "Name", value: _vm.userPromotion.name },
                    }),
                    _c("ContentCellComponent", {
                      staticClass: "mb-4",
                      attrs: { label: "Code", value: _vm.userPromotion.code },
                    }),
                    _c("ContentCellComponent", {
                      staticClass: "mb-4",
                      attrs: {
                        label: "Amount",
                        value: _vm.userPromotion.originalSaldoFormatted,
                      },
                    }),
                    _c("ContentCellComponent", {
                      attrs: {
                        label: "Available saldo",
                        value: _vm.userPromotion.availableSaldoFormatted,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("ContentCellComponent", {
                      staticClass: "mb-4",
                      attrs: {
                        label: "Activation date",
                        value: _vm.formatDate(_vm.userPromotion.activationDate),
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "mb-4" },
                      [
                        _c("MuiDatePicker", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                isRequired: true,
                                isAfterActivationDate:
                                  _vm.isAfterActivationDate,
                              },
                              expression:
                                "{\n                isRequired: true,\n                isAfterActivationDate,\n              }",
                            },
                          ],
                          staticClass: "w-100",
                          attrs: {
                            disabled: !_vm.inputs.active,
                            "skip-time-step": "",
                            "data-test-id": "expiration_date-select",
                            label: "Expiration date*",
                          },
                          model: {
                            value: _vm.inputs.expirationDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "expirationDate", $$v)
                            },
                            expression: "inputs.expirationDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mb-4" },
                      [
                        _c(
                          "label",
                          {
                            staticClass:
                              "d-block emobg-font-weight-semibold mb-1",
                          },
                          [_vm._v(" Promo status ")]
                        ),
                        _c("ui-toggle", {
                          staticClass: "mb-4 py-1 d-block",
                          attrs: {
                            value: _vm.inputs.active,
                            text: _vm.inputs.active ? "Active" : "Inactive",
                            "data-test-id": "active-toggle",
                            name: "active",
                          },
                          on: { changevalue: _vm.onActiveChange },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }