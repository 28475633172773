import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  isFalsyString,
  reformatDateTime,
} from '@emobg/web-utils';

export function contentCells({ operatorTimezone }) {
  return [
    {
      attributeName: 'internal_name',
      title: 'Internal name',
      displayPriority: 1,
      minWidth: 180,
    },
    {
      attributeName: 'name',
      title: 'Name',
      displayPriority: 1,
      columnRatio: 2,
      minWidth: 160,
    },
    {
      attributeName: 'code',
      title: 'Code',
      displayPriority: 1,
      columnRatio: 2,
      minWidth: 160,
    },
    {
      attributeName: 'activation_date',
      title: 'Activation date',
      displayPriority: 1,
      minWidth: 180,
      transformValue: value => reformatDateTime(value, DATE_FORMAT.dob, operatorTimezone),
    },
    {
      attributeName: 'expiration_date',
      title: 'Expiration date',
      displayPriority: 1,
      minWidth: 180,
      transformValue: value => reformatDateTime(value, DATE_FORMAT.dob, operatorTimezone),
    },
    {
      title: 'Amount',
      displayPriority: 1,
      minWidth: 150,
      transformResult: result => result.original_saldo_formatted,
    },
    {
      title: 'Available saldo',
      displayPriority: 1,
      minWidth: 150,
      transformResult: result => result.available_saldo_formatted,
    },
    {
      attributeName: 'active',
      title: 'Status',
      displayPriority: 1,
      minWidth: 120,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ active }) => ({
        text: active ? 'Active' : 'Inactive',
        color: active ? COLORS.success : GRAYSCALE.ground,
      }),
    },
    {
      attributeName: 'associate_user',
      title: 'Associated user',
      transformValue: value => value || FALLBACK_MESSAGE.dash,
      displayPriority: 1,
      minWidth: 120,
    },
  ];
}

export const facets = ({ operatorTimezone }) => [
  {
    type: 'DateIntervalsRange',
    props: {
      title: 'Dates',
      dateStart: 'activation_date_ts',
      dateEnd: 'expiration_date_ts',
      labels: {
        from: 'From',
        to: 'To',
        clearButton: 'Clear dates',
        none: 'None',
        rangeOverlap: 'Active promotion',
        dateStart: 'Promotion activation date',
        dateEnd: 'Promotion expiration date',
      },
      timezone: operatorTimezone,
      from: null,
      to: null,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Status',
      attributeName: 'active',
      transformValue: value => (isFalsyString(value) ? 'Inactive' : 'Active'),
    },
  },
];
