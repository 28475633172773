var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "UserPromotions",
      attrs: { "data-test-id": "user_promotions" },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center mb-2" },
        [
          _c("h1", { staticClass: "flex-grow-1" }, [_vm._v(" Promotions ")]),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "create-button" },
              on: {
                clickbutton: function ($event) {
                  _vm.isAddUserPromotionModalOpen = true
                },
              },
            },
            [_vm._v(" Add promotion ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c("MuiAlgoliaList", {
            ref: "userPromotions",
            attrs: {
              "data-test-id": "list",
              index: _vm.algoliaIndex,
              "table-config": _vm.algoliaConfig,
              "export-columns": _vm.algoliaConfig,
              filters: _vm.algoliaFilters,
              facets: _vm.algoliaFacets,
              "item-actions": _vm.itemActions,
              "is-export-enabled": "",
            },
          }),
        ],
        1
      ),
      _vm.isAddUserPromotionModalOpen
        ? _c("AddUserPromotion", {
            key: "addUserPromotion",
            attrs: {
              "user-uuid": _vm.user.uuid,
              "on-success": _vm.onSuccessForm,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isEditFormOpen
        ? _c("EditUserPromotion", {
            key: "editUserPromotion",
            attrs: {
              "user-uuid": _vm.user.uuid,
              "user-promotion": _vm.userPromotion,
              "on-success": _vm.onSuccessForm,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }