<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import moment from 'moment-timezone';
import { mapActions, mapMutations, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { MuiValidationWrapper, Validate } from '@emobg/motion-ui/v1';
import { MuiDatePicker } from '@emobg/vue-base';
import { DATE_FORMAT } from '@emobg/web-utils';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { Cancel } from '@/constants/modalButtons.const';
import { GenericModalComponent, StoreNotificationComponent } from '@/components';
import ContentCellComponent from '@/components/ContentCell/ContentCellComponent';
import { USER_SCOPES } from '../../../store/UsersModule';

export default {
  name: 'EditUserPromotion',
  components: {
    ContentCellComponent,
    GenericModalComponent,
    MuiValidationWrapper,
    MuiDatePicker,
    StoreNotificationComponent,
  },
  directives: {
    Validate,
  },
  props: {
    userUuid: {
      type: String,
      required: true,
    },
    userPromotion: {
      type: Object,
      required: true,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      inputs: {
        active: false,
        expirationDate: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorId: state => state.operators.active.id,
    }),
    ...mapState(DOMAINS_MODEL.crm.users, {
      editPromotionStatus: state => state.editPromotionForm.STATUS,
    }),
    hasSameValues() {
      return isEqual(this.formatData(this.inputs), this.formatData(this.originalInputs));
    },
    modalButtons() {
      return [
        {
          ...Cancel,
          listeners: {
            click: () => this.$emit('closeModal'),
          },
        },
        {
          attributes: {
            disabled: !this.isFormValid || this.hasSameValues,
            loading: this.editPromotionStatus.LOADING,
          },
          text: 'Save',
          listeners: {
            click: () => this.editUserPromotion(),
          },
        },
      ];
    },
  },
  created() {
    this.USER_SCOPES = USER_SCOPES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.DATE_FORMAT = DATE_FORMAT;
    this.inputs.active = !!this.userPromotion.active;
    this.inputs.expirationDate = moment(this.userPromotion.expirationDate);
    this.originalInputs = cloneDeep(this.inputs);
  },
  beforeDestroy() {
    this.resetState({ scopes: [USER_SCOPES.editPromotionForm] });
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.users, [
      'patchUserPromotion',
    ]),
    ...mapMutations(DOMAINS_MODEL.crm.users, [
      'resetState',
    ]),
    onActiveChange() {
      if (!this.inputs.active) {
        this.inputs.expirationDate = this.originalInputs.expirationDate;
      }
    },
    formatData(data) {
      const request = cloneDeep(data);
      request.expirationDate = moment(request.expirationDate).format(DATE_FORMAT.date);

      return request;
    },
    setDate(date) {
      this.inputs.expirationDate = date;
    },
    formatDate(date) {
      return moment(date).format(DATE_FORMAT.dob);
    },
    isAfterActivationDate(date) {
      return {
        isValid: date && date.isAfter(moment(this.userPromotion.activationDate)),
        message: 'Expiration must be after activation date',
      };
    },
    async editUserPromotion() {
      await this.patchUserPromotion({
        userUuid: this.userUuid,
        userPromotionUuid: this.userPromotion.uuid,
        data: this.formatData(this.inputs),
      });

      if (!this.editPromotionStatus.ERROR) {
        this.$emit('closeModal');
        this.onSuccess();
        this.$notify({
          message: 'Promotion successfully <strong class="emobg-font-weight-semibold">edited</strong>',
          textAction: '',
        });
      }
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :buttons="modalButtons"
    data-test-id="edit_promotion-form"
    class="EditUserPromotionForm"
    title="Edit promotion"
    v-on="$listeners"
  >
    <template slot="alerts">
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.users"
        :scope="USER_SCOPES.editPromotionForm"
        is-editing
        element="promotion"
      />
    </template>
    <template slot="body">
      <MuiValidationWrapper @areAllValid="isValid => isFormValid = isValid">
        <div class="row">
          <div class="col-6">
            <ContentCellComponent
              label="Internal name"
              :value="userPromotion.internalName"
              class="mb-4"
            />
            <ContentCellComponent
              label="Name"
              :value="userPromotion.name"
              class="mb-4"
            />
            <ContentCellComponent
              label="Code"
              :value="userPromotion.code"
              class="mb-4"
            />
            <ContentCellComponent
              label="Amount"
              :value="userPromotion.originalSaldoFormatted"
              class="mb-4"
            />
            <ContentCellComponent
              label="Available saldo"
              :value="userPromotion.availableSaldoFormatted"
            />
          </div>
          <div class="col-6">
            <ContentCellComponent
              label="Activation date"
              :value="formatDate(userPromotion.activationDate)"
              class="mb-4"
            />
            <div class="mb-4">
              <MuiDatePicker
                v-model="inputs.expirationDate"
                v-validate="{
                  isRequired: true,
                  isAfterActivationDate,
                }"
                :disabled="!inputs.active"
                skip-time-step
                data-test-id="expiration_date-select"
                label="Expiration date*"
                class="w-100"
              />
            </div>
            <div class="mb-4">
              <label class="d-block emobg-font-weight-semibold mb-1">
                Promo status
              </label>
              <ui-toggle
                :value="inputs.active"
                :text="inputs.active ? 'Active' : 'Inactive'"
                data-test-id="active-toggle"
                class="mb-4 py-1 d-block"
                name="active"
                @changevalue="onActiveChange"
              />
            </div>
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
  </GenericModalComponent>
</template>
