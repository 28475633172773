<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  MuiAlgoliaSelect,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { GenericModalComponent, StoreNotificationComponent } from '@/components';
import { Cancel } from '@/constants/modalButtons.const';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { USER_SCOPES } from '../../../store/UsersModule';

export default {
  name: 'AddUserPromotion',
  components: {
    GenericModalComponent,
    MuiAlgoliaSelect,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  directives: {
    Validate,
  },
  props: {
    userUuid: {
      type: String,
      required: true,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      inputs: {
        promotionUuid: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorId: state => state.operators.active.id,
    }),
    ...mapState(DOMAINS_MODEL.crm.users, {
      addPromotionFormStatus: state => state.addPromotionForm.STATUS,
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
    modalButtons() {
      return [
        {
          ...Cancel,
          listeners: {
            click: () => this.$emit('closeModal'),
          },
        },
        {
          attributes: {
            disabled: !this.isFormValid,
            loading: this.addPromotionFormStatus.LOADING,
          },
          text: 'Add',
          listeners: {
            click: () => this.addPromotion(),
          },
        },
      ];
    },
  },
  created() {
    this.USER_SCOPES = USER_SCOPES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
  },
  beforeDestroy() {
    this.resetState({ scopes: [USER_SCOPES.addPromotionForm] });
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.users, [
      'postUserPromotion',
    ]),
    ...mapMutations(DOMAINS_MODEL.crm.users, [
      'resetState',
    ]),
    async addPromotion() {
      await this.postUserPromotion({
        userUuid: this.userUuid,
        promotionUuid: this.inputs.promotionUuid,
      });

      if (!this.addPromotionFormStatus.ERROR) {
        this.$emit('closeModal');
        this.onSuccess();
        this.$notify({
          message: 'Promotion successfully <strong class="emobg-font-weight-semibold">added</strong>',
          textAction: '',
        });
      }
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :buttons="modalButtons"
    :size="SIZES.small"
    data-test-id="add_promotion-form"
    class="AddUserPromotionForm"
    title="Add promotion"
    v-on="$listeners"
  >
    <template slot="alerts">
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.users"
        :scope="USER_SCOPES.addPromotionForm"
        element="promotion"
      />
    </template>
    <template slot="body">
      <MuiValidationWrapper @areAllValid="isValid => isFormValid = isValid">
        <p class="emobg-body-1 mb-4">
          Only active promotions with valid expiration date are available.
        </p>
        <div class="mb-4">
          <MuiAlgoliaSelect
            v-model="inputs.promotionUuid"
            v-validate="{
              isRequired: true,
            }"
            :index="ALGOLIA_INDEXES.promotions"
            :title="result => result.name || result.internal_name"
            :filters="`${getOperatorFilter({ attribute: 'cs_operator_fk' })} AND deleted_at: null AND active: Active`"
            data-test-id="promotion-select"
            no-cache
            path-value="uuid"
            label="Promotion*"
            placeholder="Select a promotion"
            name="promotionUuid"
            class="w-100"
          />
        </div>
      </MuiValidationWrapper>
    </template>
  </GenericModalComponent>
</template>
