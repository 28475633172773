<script>
import { mapState } from 'vuex';
import { camelCaseKeys, DELAY, TIME_ZONE } from '@emobg/web-utils';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { refreshAlgoliaStore } from '@/utils/algolia';
import { contentCells, facets } from './config';
import AddUserPromotion from './components/AddUserPromotion';
import EditUserPromotion from './components/EditUserPromotion';

export default {
  name: 'UserPromotions',
  components: {
    AddUserPromotion,
    EditUserPromotion,
    MuiAlgoliaList,
  },
  data() {
    return {
      isLoading: false,
      isAddUserPromotionModalOpen: false,
      isEditFormOpen: false,
      userPromotion: null,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
    }),
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
    }),
    algoliaConfig() {
      return contentCells({ operatorTimezone: this.operatorTimezone });
    },
    algoliaFacets() {
      return facets({ operatorTimezone: this.operatorTimezone });
    },
  },
  created() {
    this.algoliaIndex = ALGOLIA_INDEXES.userPromotion;
    this.algoliaFilters = `user_fk:${this.user.id}`;
    this.itemActions = [
      {
        label: 'Edit promotion',
        method: promotion => {
          this.userPromotion = camelCaseKeys(promotion);
          this.isEditFormOpen = true;
        },
      },
    ];
  },
  methods: {
    closeModal() {
      this.isAddUserPromotionModalOpen = false;
      this.isEditFormOpen = false;
      this.userPromotion = null;
    },
    onSuccessForm() {
      this.isLoading = true;
      refreshAlgoliaStore(this.$refs.userPromotions, DELAY.extraLong, () => { this.isLoading = false; });
    },
  },
};
</script>
<template>
  <div
    data-test-id="user_promotions"
    class="UserPromotions"
  >
    <div class="d-flex align-items-center mb-2">
      <h1 class="flex-grow-1 ">
        Promotions
      </h1>
      <ui-button
        data-test-id="create-button"
        @clickbutton="isAddUserPromotionModalOpen = true"
      >
        Add promotion
      </ui-button>
    </div>
    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        absolute
        data-test-id="loader"
      />
      <MuiAlgoliaList
        ref="userPromotions"
        data-test-id="list"
        :index="algoliaIndex"
        :table-config="algoliaConfig"
        :export-columns="algoliaConfig"
        :filters="algoliaFilters"
        :facets="algoliaFacets"
        :item-actions="itemActions"
        is-export-enabled
      />
    </div>
    <AddUserPromotion
      v-if="isAddUserPromotionModalOpen"
      key="addUserPromotion"
      :user-uuid="user.uuid"
      :on-success="onSuccessForm"
      @closeModal="closeModal"
    />
    <EditUserPromotion
      v-if="isEditFormOpen"
      key="editUserPromotion"
      :user-uuid="user.uuid"
      :user-promotion="userPromotion"
      :on-success="onSuccessForm"
      @closeModal="closeModal"
    />
  </div>
</template>
